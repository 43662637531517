import { formatDate } from 'utils/dates'
import handleHtmlData from 'utils/global/handleHtmlData'

export default ({ editorProps, templateProps }) => {
  const { caseDetails, dateFormat } = editorProps
  const { serviceIndex } = templateProps

  const templateData = { serviceIndex }
  const encodedProperties = handleHtmlData.encode(templateData)

  const services = caseDetails?.services
  const currentService = services?.[serviceIndex]

  if (!currentService) return ''

  const {
    location,
    address,
    city,
    state,
    postal_code,
    date,
    start_time,
    end_time,
  } = currentService

  const locationParagraph = location ? `<p><span>${location}</span></p>` : ''

  const addressSpan = address ? `<span>${address}</span>, ` : ''
  const citySpan = city ? `<span>${city}</span>, ` : ''
  const stateSpan = state ? `<span>${state}</span> ` : ''
  const postalCodeSpan = postal_code ? `<span>${postal_code}</span>` : ''

  const hasAddressProperties = address || city || state || postal_code
  const addressParagraph = hasAddressProperties
    ? `<p>${addressSpan}${citySpan}${stateSpan}${postalCodeSpan}</p>`
    : ''

  const formattedDate = formatDate(date, dateFormat)
  const dateParagraph = date ? `<p><span>${formattedDate}</span></p>` : ''

  const startTimeSpan = start_time ? `<span>${start_time}</span>` : ''
  const endTimeSpan = end_time ? `<span>${end_time}</span>` : ''
  const timeParagraph =
    start_time && end_time ? `<p>${startTimeSpan} to ${endTimeSpan}</p>` : ''

  const html = `
    <div data-template="service" data-properties="${encodedProperties}" class="mceNonEditable">
      ${locationParagraph}
      ${addressParagraph}
      ${dateParagraph}
      ${timeParagraph}
    </div>
  `

  return html
}
