import React from 'react'
import { Flex, Text, Button } from 'components'
import { useNavigate } from 'hooks'
import { useLocation } from 'react-router-dom'
import buildUrlWithParams from 'utils/global/buildUrlWithParams'

export default ({ setShow, orderId }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const handleBack = () => {
    setShow()
    navigate(buildUrlWithParams(`/package/${orderId}/customize`, params))
  }
  return (
    <Flex column full padding="32px">
      <Text size="20px" weight="600" margin="10px 0 10px 0">
        Progress is unsaved and will be lost.
      </Text>
      <Flex justify="flex-end" margin="10px 0 0 0">
        <Button margin="0 16px 0 0" onClick={setShow}>
          Cancel
        </Button>
        <Button secondary margin="0 16px 0 0" onClick={() => handleBack()}>
          Exit to dashboard
        </Button>
      </Flex>
    </Flex>
  )
}
