import { TableCell, TableActions } from 'components'
import { formatDate } from 'utils'
import sortTableDates from 'utils/global/sortTableDates'

const rightAligned = { display: 'flex', justifyContent: 'flex-end' }

export default ({
  itemsLoaded,
  showingArchived,
  archive,
  createTukiosFilm,
  editPackage,
  openDuplicateOrder,
  tukiosIconDisabled,
}) => [
  // Deceased Name
  {
    Header: 'Deceased Name',
    accessor: 'case.name_of_deceased',
    Cell: ({ value }) => (
      <TableCell text={value || '---'} loaded={itemsLoaded} />
    ),
  },

  // Case
  {
    Header: 'Case',
    accessor: 'case.editable_case_id',
    Cell: ({
      row: {
        original: {
          case: { editable_case_id },
          case_id,
        },
      },
    }) => (
      <TableCell
        text={editable_case_id || case_id}
        loaded={itemsLoaded}
        truncate={13}
      />
    ),
  },

  // Stationery
  {
    Header: 'Stationery',
    accessor: 'collection_name',
    Cell: ({ value }) => <TableCell text={value} loaded={itemsLoaded} />,
  },

  // Date of Death
  {
    Header: 'Date of Death',
    accessor: 'case.date_of_death',
    style: rightAligned,
    sortType: (rowA, rowB) =>
      sortTableDates(rowA, rowB, 'sortable_date_of_death'),
    Cell: ({ value }) => (
      <TableCell text={formatDate(value, 'PP')} loaded={itemsLoaded} />
    ),
  },

  // Date Created
  {
    Header: 'Date Created',
    accessor: 'created_at',
    style: rightAligned,
    sortType: (rowA, rowB) => sortTableDates(rowA, rowB, 'sortable_created_at'),
    Cell: ({ value }) => (
      <TableCell text={formatDate(value, 'PP')} loaded={itemsLoaded} />
    ),
  },

  // Created By
  {
    Header: 'Created By',
    accessor: 'created_by_name',
    Cell: ({ value }) => <TableCell text={value} loaded={itemsLoaded} />,
  },

  // Actions
  {
    Header: 'Actions',
    style: rightAligned,
    accessor: '',
    disableSortBy: true,
    Cell: ({ row }) => (
      <TableActions
        loaded={itemsLoaded}
        actions={[
          {
            text: showingArchived ? 'Unarchive' : 'Archive',
            icon: 'archive',
            onClick: () => archive(false, row),
          },
          {
            text: 'Tukios',
            icon: 'film',
            onClick: () =>
              createTukiosFilm({
                order_id: row.original.id,
                case_id: row.original.case_id,
                date_of_death: row.original.case.date_of_death,
                date_of_birth: row.original.case.date_of_birth,
                name_of_deceased: row.original.case.name_of_deceased,
              }),
            disabled: tukiosIconDisabled,
          },
          {
            text: 'Duplicate order',
            icon: 'duplicate',
            //open cases modal
            onClick: () => openDuplicateOrder(row.original.id),
          },
          {
            text: 'Edit package',
            icon: 'pencil',
            onClick: () => editPackage(row.original.id),
          },
        ]}
      />
    ),
  },
]
