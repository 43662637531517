import React, { useRef } from 'react'
import { Flex, Button, TinyMCE } from 'components'
import { case_details, tinyMCEDefaults } from 'mocks'
import { replaceInitialValues } from 'utils/tinyMCE/replaceInitialValues'

export default () => {
  const editorRef = useRef(null)

  return (
    <Flex center background="paleSecondary" column>
      <Button
        margin="12px 12px 32px 12px"
        onClick={() => {
          const editor = editorRef.current
          const content = editor.getContent()
          const newValue = replaceInitialValues(content, case_details)
          editor.setContent(newValue)
        }}
      >
        Replace Text
      </Button>
      <Flex width="90vw" height="300px">
        <TinyMCE
          setRef={(ref) => (editorRef.current = ref)}
          value={tinyMCEDefaults}
          caseDetails={case_details}
        />
      </Flex>
    </Flex>
  )
}
