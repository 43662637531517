const frontEndDomain = (): string => {
  try {
    const { protocol, host } = window.location
    if (!protocol || !host) {
      console.error('Missing protocol or host:', { protocol, host })
      return ''
    }
    return `${protocol}//${host}`
  } catch (error) {
    console.error('Error getting front end domain:', error)
    return ''
  }
}

export default frontEndDomain()
