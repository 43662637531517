import { atom } from 'recoil'

// Base atom to store all currently selected product data
// e.g. the product that is currently being customized, or the product that is being previewed via tab selection, etc.
export const productDataAtom = atom({
  key: 'productDataAtom',
  default: {
    id: null,
    name: null,
  },
})
