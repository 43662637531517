import React, { useEffect, useState } from 'react'
import { Flex, Text, Button, Dropdown, Input, Icon, Tag } from 'components'
import {
  update as updatePageSets,
  archive,
  getCustomPageSets,
  updatePagesByPageSetsId,
} from 'api/pageSets'
import { getPages } from 'api/pages'
import { update as updateOrderPageSets } from 'api/orderPageSets'
import { createOrderPage, archiveOrderPage } from 'api/orderPages'
import {
  editorLayoutAtom,
  editorPagesAtom,
  editorOrderPageSetAtom,
  triggerUseEffect,
  themeIdAtom,
  roleAtom,
  selectedProductsAtom,
} from 'atoms'
import { orderDataAtom } from 'atoms/orderAtoms'
import { productDataAtom } from 'atoms/productAtoms'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import { useSetToast } from 'hooks'
import { default as _omit } from 'lodash/omit'
import { Tooltip } from 'components/global/Tooltip/Tooltip'
import { ToggleSwitch } from 'components/global/ToggleSwitch/ToggleSwitch'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'
import { camelToSnakeCase, createPageSetPages } from 'utils'
import { isEmpty } from 'lodash'

export default ({ setShow, basePages, baseLayout, setIsLoading }) => {
  const handleError = useErrorHandler()
  const layoutId = useRecoilValue(editorLayoutAtom)
  const editorPages = useRecoilValue(editorPagesAtom)
  const [orderPageSet, setOrderPageSet] = useRecoilState(editorOrderPageSetAtom)
  const [layouts, setLayouts] = useState([])
  const [selectedLayoutId, setSelectedLayoutId] = useState(null)
  const [selectedLayout, setSelectedLayout] = useState(null)
  const [layoutIsDefault, setLayoutIsDefault] = useState(null)
  const [layoutName, setLayoutName] = useState(null)
  const [currentLayout, setCurrentLayout] = useState(null)
  const themeId = useRecoilValue(themeIdAtom)
  const setToast = useSetToast()
  const setTrigger = useSetRecoilState(triggerUseEffect)
  const [isGlobalTemplate, setIsGlobalTemplate] = useState(null)
  const role = useRecoilValue(roleAtom)
  const [orderData, setOrderData] = useRecoilState(orderDataAtom)
  const productData = useRecoilValue(productDataAtom)
  const selectedProducts = useRecoilValue(selectedProductsAtom)

  const isMessengerUser = role === 'messenger-user'

  const getSets = async () => {
    try {
      if (!layoutId || !themeId) return

      const { data } = await getCustomPageSets(layoutId, themeId)

      let homeLayouts = data
        .filter((item) => item.home_template && !item.default)
        .sort((a, b) => (a.name < b.name ? -1 : 0))

      let adminLayouts = data
        .filter((item) => !item.home_template && !item.default)
        .sort((a, b) => (a.name < b.name ? -1 : 0))

      const adminLayoutDefault = data.filter(
        (item) => item.default && !item.home_template,
      )
      const homeLayoutDefault = data.filter(
        (item) => item.default && item.home_template,
      )

      const finalLayouts = adminLayoutDefault
        .concat(adminLayouts)
        .concat(homeLayoutDefault)
        .concat(homeLayouts)

      setLayouts(finalLayouts)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const archiveLayout = async () => {
    try {
      await archive(selectedLayout.id)

      setToast({
        text: 'Layout deleted!',
      })
      getSets()
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const updateTemplate = async () => {
    try {
      await _updatePageSets()

      const { data: archivedCurrentPages } = await updatePagesByPageSetsId(
        selectedLayoutId,
        { archived: true },
      )

      if (archivedCurrentPages?.count === 0) {
        throw new Error('Failed to archive current pages')
      }

      await createPageSetPages({
        basePages,
        editorPages,
        pageSet: selectedLayout,
      })
    } catch (error) {
      await updatePagesByPageSetsId(selectedLayoutId, { archived: false })
      throw error
    }
  }

  const _updatePageSets = async () => {
    const currentDefault = layouts.find(
      (layout) => layout.home_template && layout.default,
    )

    if (layoutIsDefault && currentDefault)
      await updatePageSets(currentDefault.id, { default: false })

    const payload = {
      default: layoutIsDefault,
      name: layoutName,
      ...(isMessengerUser && { home_template: !isGlobalTemplate }),
    }
    await updatePageSets(selectedLayout.id, payload)
    getSets()
  }

  const updateLayout = async () => {
    try {
      await updateTemplate()

      const notificationMessage =
        isMessengerUser && isGlobalLayout
          ? 'Global Template updated!'
          : 'Layout updated!'

      setToast({ text: notificationMessage })
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const archiveCurrentPages = async () => {
    try {
      for (const page of basePages) {
        await archiveOrderPage(page.id, true)
      }
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const createNewOrderPages = async () => {
    try {
      const { data } = await getPages(selectedLayout.id)
      const newPages = []
      for (const page of data) {
        const json = page.json_template
        const newPage = _omit(page, ['archived', 'id', 'json_template'])
        newPage.order_page_set_id = basePages?.[0].order_page_set_id
        newPage.json_template = JSON.stringify(json)
        const { data: newPageData } = await createOrderPage(newPage)
        newPageData.json_template.product.orderPageId = newPageData.id
        newPages.push(newPageData)
      }
      return newPages
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const applyLayout = async () => {
    try {
      setIsLoading(true)
      await archiveCurrentPages()
      //now we have the base page sets, we need to create versions to attach to the order

      const payload = _omit(selectedLayout, [
        'archived',
        'id',
        'order_layout_id',
      ])
      payload.page_set_id = selectedLayout.id
      // payload.order_layout_id = baseLayout.id
      //this will also create the 'page' objects and return both data: {page_set: {}, pages: []}
      if (!payload.funeral_home_id) delete payload.funeral_home_id
      const snakeCasedPayload = camelToSnakeCase(payload)
      const { data: newPageSetData } = await updateOrderPageSets(
        orderPageSet.id,
        snakeCasedPayload,
      )
      const newPages = await createNewOrderPages()
      if (isEmpty(newPages)) throw Error('Error at updating order pages')

      setCurrentLayout(newPageSetData)
      setOrderPageSet(newPageSetData)
      setSelectedLayoutId(newPageSetData.id)
      setTrigger(Date.now())
      setIsLoading(false)
      if (!productData?.id) {
        // if a user refreshes the page, the recoil state will be reset
        // they will need to re-select a page set different from the default again
        console.warn('this layout will not save without a product id')
      }
      productData?.id &&
        setOrderData({
          ...orderData,
          pageSets: {
            ...orderData.pageSets,
            [productData.id]: {
              ...orderData.pageSets[productData.id],
              pageSet: newPageSetData,
            },
          },
        })
      await new Promise((resolve) => setTimeout(resolve, 2000))
      setShow(false)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  useEffect(() => {
    getSets()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    let current = layouts.find((item) => item.id === orderPageSet.page_set_id)
    setCurrentLayout(current)
    current && setSelectedLayoutId(current.page_set_id)
    //eslint-disable-next-line
  }, [layouts, orderPageSet])

  useEffect(() => {
    let layout = layouts.find((item) => item.id === selectedLayoutId)
    // layout = !layout
    //   ? adminLayouts.find((item) => item.id === selectedLayoutId)
    //   : layout
    layout && setLayoutIsDefault(layout?.default || false)
    layout?.name && setLayoutName(layout.name)
    layout && setSelectedLayout(layout)
    layout && setIsGlobalTemplate(!layout?.home_template)
    //eslint-disable-next-line
  }, [selectedLayoutId, layouts])

  const showSavedLayoutControls =
    selectedLayout?.home_template || isMessengerUser
  const isGlobalLayout = !selectedLayout?.home_template

  return (
    <Flex margin="16px 24px" column>
      <Flex margin="16px 0px">
        <Text size="16px">Current Layout: </Text>
        <Text size="16px" weight="600">
          {currentLayout?.name}
        </Text>
      </Flex>
      <Flex column>
        {/* <Text>Default Layouts</Text>
        <Dropdown
          selected={[selectedLayoutId, setSelectedLayoutId]}
          placeholder="Default layouts"
          textKey="name"
          valueKey="id"
          customLayout
          items={adminLayouts}
          margin="16px"
        /> */}

        <Text>Your Saved Layouts</Text>
        <Dropdown
          selected={[selectedLayoutId, setSelectedLayoutId]}
          placeholder="Saved layouts"
          textKey="name"
          customLayout
          valueKey="id"
          items={layouts}
          margin="16px"
        />
      </Flex>

      {selectedLayout && (
        <Flex column background="gray5" padding="16px">
          <Flex justify="space-between" align="center" margin="16px 0">
            <Flex column gap="6px">
              <Text>
                Selected:
                <Text size="18" weight="600">
                  {selectedLayout.name}
                </Text>
              </Text>
              {isGlobalLayout && (
                <Flex justify="start">
                  <Tag>Global template</Tag>
                </Flex>
              )}
            </Flex>
            <Button primary onClick={applyLayout}>
              Apply to Product
            </Button>
          </Flex>

          {showSavedLayoutControls && (
            <Flex column gap="12px">
              <Flex
                justify="space-between"
                align="center"
                padding="16px 0 8px"
                borderTop="1px solid #B0B6BC"
              >
                <Flex justify="space-between">
                  <Tooltip
                    text="Change name of this saved layout"
                    margin="0 12px 0 0"
                  >
                    <Input
                      label="Layout Name"
                      placeholder="Layout name"
                      value={[layoutName, setLayoutName]}
                      width="200px"
                      disabled={!showSavedLayoutControls}
                    />
                  </Tooltip>
                  <Tooltip text="Make this layout default" margin="0 12px 0 0">
                    <ToggleSwitch
                      label="Default"
                      value={[layoutIsDefault, setLayoutIsDefault]}
                      margin="0 16px"
                      disabled={!showSavedLayoutControls}
                    />
                  </Tooltip>
                </Flex>
                <Flex justify="flex-end">
                  <Tooltip text="Delete Layout" margin="0 12px 0 0">
                    <Icon
                      height="16px"
                      width="16px"
                      margin="8px"
                      icon="archive"
                      fill="brand"
                      onClick={() => {
                        archiveLayout()
                      }}
                    />
                  </Tooltip>
                </Flex>
              </Flex>
              {isMessengerUser && (
                <Flex justify="flex-start">
                  <Tooltip
                    text="Make this layout global template"
                    margin="0 12px 0 0"
                  >
                    <ToggleSwitch
                      label="Is global template"
                      labelRight
                      value={[isGlobalTemplate, setIsGlobalTemplate]}
                      margin="0 16px"
                      disabled={!showSavedLayoutControls}
                    />
                  </Tooltip>
                </Flex>
              )}
              <Flex justify="space-between">
                <Button
                  disabled={!showSavedLayoutControls}
                  onClick={updateLayout}
                >
                  Save
                </Button>
              </Flex>
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  )
}
