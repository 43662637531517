import { fonts } from 'utils'
import { formatDate } from 'utils'
import type { SettingsForm, PrintSetting } from 'types/settingsForm'

const today = new Date()
const printSettings: PrintSetting[] = [
  { valueKey: '0.00000', textKey: 'None' },
  { valueKey: '0.03125', textKey: '1/32"' },
  { valueKey: '0.04160', textKey: '1/24"' },
  { valueKey: '0.06250', textKey: '1/16"' },
  { valueKey: '0.12500', textKey: '1/8"' },
  { valueKey: '0.25000', textKey: '1/4"' },
]

const settingsForm: SettingsForm = {
  preferences: [
    {
      key: 'default_font',
      type: 'dropdown',
      label: 'Default Font',
      dropdownOptions: fonts,
      fontMenu: true,
      textKey: 'name',
      valueKey: 'name',
    },
    {
      key: 'date_format',
      type: 'dropdown',
      label: 'Default Date Format',
      textKey: 'textKey',
      valueKey: 'valueKey',
      dropdownOptions: [
        { textKey: formatDate(today, 'M/d/yy'), valueKey: 'M/d/yy' },
        { textKey: formatDate(today, 'MM/dd/yy'), valueKey: 'MM/dd/yy' },
        { textKey: formatDate(today, 'M/d/yyyy'), valueKey: 'M/d/yyyy' },
        { textKey: formatDate(today, 'MM/dd/yyyy'), valueKey: 'MM/dd/yyyy' },
        { textKey: formatDate(today, 'yyyy MMM d'), valueKey: 'yyyy MMM d' },
        { textKey: formatDate(today, 'yyyy MMMM d'), valueKey: 'yyyy MMMM d' },
        { textKey: formatDate(today, 'yyyy MMM do'), valueKey: 'yyyy MMM do' },
        {
          textKey: formatDate(today, 'yyyy MMMM do'),
          valueKey: 'yyyy MMMM do',
        },
        { textKey: formatDate(today, 'MMM d, yyyy'), valueKey: 'MMM d, yyyy' },
        {
          textKey: formatDate(today, 'MMM do, yyyy'),
          valueKey: 'MMM do, yyyy',
        },
        {
          textKey: formatDate(today, 'MMMM d, yyyy'),
          valueKey: 'MMMM d, yyyy',
        },
        {
          textKey: formatDate(today, 'MMMM do, yyyy'),
          valueKey: 'MMMM do, yyyy',
        },
        {
          textKey: formatDate(today, 'EEEE, MMMM d, yyyy'),
          valueKey: 'dddd, MMMM d, yyyy',
        },
        {
          textKey: formatDate(today, 'EEEE, MMMM do, yyyy'),
          valueKey: 'dddd, MMMM do, yyyy',
        },
      ],
    },
  ],
  headers: [
    {
      key: 'case_title_clergy_name',
      type: 'input',
      label: 'Clergy',
    },
    {
      key: 'case_title_date_of_birth',
      type: 'input',
      label: 'Date of Birth',
    },
    {
      key: 'case_title_date_of_death',
      type: 'input',
      label: 'Date of Death',
    },
    {
      key: 'case_title_interment_place',
      type: 'input',
      label: 'Interment Place',
    },
    {
      key: 'case_title_name_of_deceased',
      type: 'input',
      label: 'In Memory of',
    },
    {
      key: 'case_title_service_date',
      type: 'input',
      label: 'Service Date',
    },
  ],

  printSettings: [
    {
      key: 'print_parameter_top',
      type: 'dropdown',
      label: 'Top',
      textKey: 'textKey',
      valueKey: 'valueKey',
      dropdownOptions: printSettings,
    },
    {
      key: 'print_parameter_bottom',
      type: 'dropdown',
      label: 'Bottom',
      textKey: 'textKey',
      valueKey: 'valueKey',
      dropdownOptions: printSettings,
    },
    {
      key: 'print_parameter_left',
      type: 'dropdown',
      label: 'Left',
      textKey: 'textKey',
      valueKey: 'valueKey',
      dropdownOptions: printSettings,
    },
    {
      key: 'print_parameter_right',
      type: 'dropdown',
      label: 'Right',
      textKey: 'textKey',
      valueKey: 'valueKey',
      dropdownOptions: printSettings,
    },
  ],
}

export default settingsForm
