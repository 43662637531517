import { css } from 'styled-components'
import { elements } from 'styles'
import { colors as c, propsToCSS } from 'utils'
import type { StyledFunction } from 'types/style'
import type { StyleProps } from 'utils/styling/propsToCSS'

const propStyles = {
  error: css`
    border: 1px solid ${c('secondary')};
  `,
}

const typeArea: StyledFunction<StyleProps> = ({ ...props }) => css`
  font-size: 14px;
  font-weight: 400;

  &::placeholder {
    color: ${c('gray2')};
    user-select: none;
  }

  &:focus {
    background: ${c('white')};
    border: 1px solid ${c('gray2')};
  }

  ${propsToCSS(props, propStyles)}
  ${elements.default}
`

export default typeArea
