import React, { useState } from 'react'
import { Flex, Text, Button, ModalButton, TinyMCE } from 'components'
import { deleteCustomVerse } from 'api/customVerses'
import { useRecoilValue, useRecoilCallback } from 'recoil'
import { itemAtomFamily, itemIdAtom } from 'atoms'
import { v4 as uuidv4 } from 'uuid'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default ({ verse, reload, setEditing, setShow }) => {
  const handleError = useErrorHandler()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const itemIds = useRecoilValue(itemIdAtom)

  const deleteVerse = async (id) => {
    try {
      await deleteCustomVerse(id)
      setConfirmOpen(false)
      reload()
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const addToItems = useRecoilCallback(({ set }) => (item) => {
    set(itemAtomFamily(item.id), item)
    set(itemIdAtom, [...itemIds, item.id])
  })

  const InsertVerse = async () => {
    const uuid = uuidv4()

    const textObj = {
      id: uuid,
      type: 'text',
      x: 0,
      y: 0,
      z: 20,
      width: 250,
      height: 250,

      text: {
        value: verse.html,
        edits: {},
      },
    }
    addToItems(textObj)
    setShow(false)
  }

  return (
    <Flex full column>
      <Flex full margin="0 0 12px 0" clickable onClick={() => InsertVerse()}>
        <Flex full clickThrough padding="1px" background="lockedWhite">
          <TinyMCE init={{ toolbar: [] }} value={verse.html} />
        </Flex>
      </Flex>
      <Flex>
        <ModalButton
          text="Delete"
          margin="0 12px 0 0"
          show={[confirmOpen, setConfirmOpen]}
        >
          <Flex margin="24px">
            <Flex column>
              <Text weight="600" size="16px" margin="0 0 24px 0">
                Are you sure?
              </Text>
              <Flex>
                <Button
                  margin="0 12px 0 0"
                  onClick={() => setConfirmOpen(false)}
                >
                  Cancel
                </Button>
                <Button destructive onClick={() => deleteVerse(verse.id)}>
                  Confirm
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </ModalButton>
        <Button primary onClick={() => setEditing(true)} margin="0 12px 0 0">
          Edit
        </Button>
      </Flex>
    </Flex>
  )
}
