import type { CaseDate, ServiceDate, StationDate } from '../../types/case'

type CaseDates = {
  case: CaseDate[]
  service: ServiceDate[]
  station: StationDate[]
}

const caseDates: CaseDates = {
  case: [
    'created_at',
    'updated_at',
    'archived_at',
    'date_of_birth',
    'date_of_death',
    'date_of_marriage',
    'interment_date',
    'date_entered_service',
    'date_discharged_from_service',
    'service_date',
  ],

  service: ['created_at', 'updated_at', 'date'],

  station: ['departure_date', 'return_date'],
}

export default caseDates
