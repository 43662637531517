import axios from 'axios'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import { api } from 'utils'

const instance: AxiosInstance = axios.create({ baseURL: api })

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const messengerOrderIdToken = localStorage.getItem('messengerOrderIdToken')
    if (messengerOrderIdToken) {
      config.headers = config.headers || {}
      config.headers['x-order'] = messengerOrderIdToken
    }
    return config
  },
  (error) => Promise.reject(error),
)

instance.defaults.withCredentials = false

export default instance
