import { atom } from 'recoil'

type PageSet = {
  id: string
  name: string
  page_set_id: string
  funeral_home_id: string | null
  home_template: boolean
  default: boolean
  layout_id: string
  order_layout_id: string
  archived: boolean
  archived_at: string | null
  archived_by: string | null
  created_at: string
  created_by: string
  updated_at: string
  updated_by: string
}

export type Product = {
  id: string
  name: string
  collection_id: string
  order_id: string
  thumbnail: string
  selected: boolean
  product_id: string
  archived: boolean
  archived_at: string | null
  archived_by: string | null
  created_at: string
  created_by: string
  updated_at: string
  updated_by: string
  status: string
}

type Order = {
  id: string
  funeral_home_id: string
  case_id: string
  theme_id: string
  collection_id: string
  archived: boolean
  archived_at: string | null
  archived_by: string | null
  created_at: string
  created_by: string
  updated_at: string
  updated_by: string
  selected_for_service: boolean
}

type PageSets = {
  [productId: string]: {
    pageSet: PageSet
    name: string
  }
}

type OrderData = {
  order: Order
  products: Product[]
  pageSets: PageSets
}

// Base atom to store all order-related data
export const orderDataAtom = atom<OrderData>({
  key: 'orderDataAtom',
  default: {
    // @ts-ignore - just show that an order can be used here if its useful, so far all I need to reference are it's products and their page sets
    order: {},
    products: [], // an order can have multiple products
    pageSets: {}, // an order can have multiple products, each with their selected page set
  },
})
